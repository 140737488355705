<template>
  <div class="page-root tools-baozhi-root">
    <div class="tools-banner-wrapper">
      <div class="tools-banner">
        <div class="text">
          <div class="en">VEHICLE VALUE</div>
          <div class="zh">车辆保值率查询</div>
          <div class="en">ENQUIRY</div>
          <div class="line"></div>
        </div>
        <div class="box"></div>
        <img src="@/assets/pexels-maria-geller-2127022@2x.png" />
      </div>
    </div>
    <div class="tabs-wrapper">
      <div class="title">车辆保值率查询</div>
      <div class="list-bar">
        <div class="list">
          <cyt-car-select class="hidden-md-and-down" :onlySeries="true" @carSelected="value => this.pcValue = value"/>
          <van-field
            class="hidden-md-and-up"
            v-model="fieldValue"
            is-link
            readonly
            placeholder="请选择品牌/车系"
            @click="show = true"
          />
          <van-popup v-model="show" round position="bottom">
            <van-cascader
              v-model="value1"
              title="品牌/车系"
              :options="options"
              @close="show = false"
              @change="onChange"
              @finish="onFinish"
            />
          </van-popup>
        </div>
        <div class="butt">
          <div @click="search">开始查询</div>
        </div>
      </div>
      <div class="tips">注：保值率又称残值，指某款车系您使用了一段时间，市场出售的价值比例</div>
    </div>
    <div class="result-item2">
      <div class="title"><span></span> 热门车型</div>
      <el-table size="small" :data="tableData" style="width: 100%; margin-top: 10px">
        <el-table-column prop="brandName" label="品牌车型">
          <template slot-scope="scope"> {{ scope.row.brandName }}{{ scope.row.vehicleSeriesName }} </template>
        </el-table-column>
        <el-table-column prop="priceMin" label="新车指导价格(万元)">
          <template slot-scope="scope">
            {{ formatNum(scope.row.priceMin) }} -
            {{ formatNum(scope.row.priceMax) }}
          </template>
        </el-table-column>
        <el-table-column prop="ratio" label="保值率">
          <template slot-scope="scope"> 五年保值率: {{ scope.row.ratio }}% </template>
        </el-table-column>
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { get } from '@/utils/axios';
import CytCarSelect from "@/components/CytCarSelect/CytCarSelect.vue";
export default {
  name: 'Tools-baozhi',
  components: {
    CytCarSelect
  },
  methods: {
    onChange({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        get(`/system/brand/list/s/${value}`).then((res) => {
          this.options[selectedOptions[0].index].children = res.data.map((item, i) => {
            return {
              index: i,
              text: item.vehicleSeriesName,
              value: item.vehicleSeriesId,
            };
          });
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.pcValue = selectedOptions.map((v) => v.value);
    },
    formatNum(num) {
      return num ? (Number(num) / 10000).toFixed(2) : '';
    },
    //热门车型查询保值率
    handleClick(row) {
      this.$router.push({
        name: 'Tools-bzResult',
        query: {
          brandId: row.brandId,
          seriesId: row.vehicleSeriesId,
        },
      });
    },
    search() {
      if (!this.pcValue.length) {
        this.$message({
          message: '车系未选择 !',
          type: 'warning',
        });
      } else {
        this.$router.push({
          name: 'Tools-bzResult',
          query: {
            brandId: this.pcValue[0],
            seriesId: this.pcValue[1],
            fieldValue: this.fieldValue,
          },
        });
      }
    },
  },
  data() {
    return {
      show: false,
      fieldValue: '',
      options: [],
      value1: '',
      pcValue: [],
      tableData: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let url = '',
            nodes = [];
          if (level == 1) {
            url = `/system/brand/list/s/${value}`;
          } else {
            url = '/system/brand/list';
          }
          get(url).then((res) => {
            if (level == 1) {
              nodes = res.data.map((item) => {
                return {
                  value: item.vehicleSeriesId,
                  label: item.vehicleSeriesName,
                  leaf: level >= 1,
                };
              });
            } else {
              nodes = res.rows.map((item) => {
                return {
                  label: item.brandName,
                  value: item.brandId,
                  leaf: level >= 1,
                };
              });
            }
            resolve(nodes);
          });
        },
      },
    };
  },
  mounted() {
    //查询热门车型
    get('/system/ratio/listAfter5Years?pageNum=1&pageSize=5').then((res) => {
      this.tableData = res.rows;
    });
    //查询品牌
    get('/system/brand/list').then((res) => {
      this.options = res.rows.map((item, i) => {
        return {
          index: i,
          text: item.brandName,
          value: item.brandId,
          children: [],
        };
      });
    });
  },
};
</script>
